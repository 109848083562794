import { AxiosRequestConfig } from "axios";

import {
  QueryClient,
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { useMemo } from "react";
import { toast } from "react-toastify";
import { useAuth } from "src/state/AuthContext";
import {
  AgentableId,
  DocumentId,
  DocumentReview,
  DocumentReviewActionDetail,
  DocumentReviewStatusGroup,
  DocumentReviewType,
  DocumentVersionNumber,
  PacketId,
  ReviewTaskStatus,
  UserRelationship,
} from "src/types";
import { api } from "../api";
import { ApiRoutes } from "../apiRoutes";
import {
  PendingDocumentReviewOperation,
  applyLocalOperations,
  beginDocReviewMutation,
  getDocReviewPending,
  resolveDocReviewMutation,
  setDocReviewPending,
} from "../pending/documentReviewPending";
import { useEntitySubscription } from "../sockets";
import {
  CacheKeyLibrary,
  clearCache,
  entityKeys,
  updateCache,
} from "./apiCache";
import {
  ApiResponse,
  CollectionHook,
  CreateParams,
  GetParams,
  PagedQueryConf,
  SearchQueryConf,
  SortableQueryConf,
} from "./types";

//
// Types
//
export type DocumentReviewApiDetail = ApiResponse<{
  documentReview: DocumentReview;
}>;

export type DocumentReviewsApiIndex = ApiResponse<{
  documentReviews: DocumentReview[];
}>;

export type DocumentReviewActionsApiDetail = ApiResponse<{
  actions: DocumentReviewActionDetail[];
}>;

export type GetDocumentReviewsParams = PagedQueryConf &
  SearchQueryConf &
  SortableQueryConf & {
    type?: DocumentReviewType;
    status?: DocumentReviewStatusGroup;
    as?: UserRelationship;
  };
type GetDocumentReviewParams = GetParams;
export type CreateDocumentReviewParams = CreateParams<
  DocumentReview & {
    document: PacketId;
    reviewerIds: string[];
    type: DocumentReviewType;
    version: number;
    virtual?: boolean;
    allowSignatures: boolean | undefined;
  }
>;

type UpdateDocumentReviewerParams = {
  id: string;
  data: { reviewerIds: string[] };
};

type UpdateDocumentReviewParams = {
  id: string;
  data: { title?: string; description?: string };
};

type ResendDocumentReviewInvitationParams = {
  id: string;
  reviewerId: AgentableId;
};

type CloseReviewParams = {
  id: string;
  approved?: boolean;
};

type CreateCommentParams = {
  reviewId: string;
  content: string;
};

type CreateTaskParams = {
  reviewId: string;
  commentId?: string;
  title: string;
};

type UpdateTaskParams = {
  reviewId: string;
  taskId: string;
  title?: string;
  status?: ReviewTaskStatus;
};

type DeleteTaskParams = {
  reviewId: string;
  taskId: string;
};

//
// Networking
//

const createDocumentReview = async (params: CreateDocumentReviewParams) => {
  const resp = await api.post<DocumentReviewApiDetail>(
    ApiRoutes.documentReviews(),
    params.data,
  );

  return resp.data;
};

const getDocumentReviews = async (
  params: GetDocumentReviewsParams,
  conf: AxiosRequestConfig,
) => {
  const resp = await api.get<DocumentReviewsApiIndex>(
    ApiRoutes.documentReviews(),
    {
      params,
      ...conf,
    },
  );

  return resp.data;
};

const getDocumentReview = async (
  { id }: GetDocumentReviewParams,
  conf: AxiosRequestConfig,
) => {
  const resp = await api.get<DocumentReviewApiDetail>(
    ApiRoutes.documentReview({ id }),
    conf,
  );

  return resp.data;
};

const updateReview = async ({ id, data }: UpdateDocumentReviewParams) => {
  const res = await api.patch<DocumentReviewApiDetail>(
    ApiRoutes.documentReview({ id }),
    data,
  );

  return res.data;
};

const resendInvitation = async ({
  id,
  reviewerId,
}: ResendDocumentReviewInvitationParams) => {
  await api.post(ApiRoutes.documentReviewResendInvitation({ id }), {
    reviewerId,
  });
};

type SignDocumentParams = {
  reviewId: string;
  documents: {
    documentId: DocumentId;
    version: DocumentVersionNumber;
  }[];
  signatureFont: string;
  signatureMark: string;
  electronicSignatureConsent: true;
};

const signReview = async ({ reviewId, ...data }: SignDocumentParams) => {
  const res = await api.post<DocumentReviewApiDetail>(
    ApiRoutes.signDocumentReview({ id: reviewId }),
    data,
  );

  return res.data;
};

const getDocumentReviewActions = async (
  { id }: GetDocumentReviewParams,
  conf: AxiosRequestConfig,
) => {
  const resp = await api.get<DocumentReviewActionsApiDetail>(
    ApiRoutes.documentReviewActions({ reviewId: id }),
    conf,
  );

  return resp.data;
};

const updateReviewers = async ({ id, data }: UpdateDocumentReviewerParams) => {
  const res = await api.put<DocumentReviewApiDetail>(
    ApiRoutes.documentReviewReviewers({ id }),
    data,
  );

  return res.data;
};

const closeReview = async ({ id, approved }: CloseReviewParams) => {
  const res = await api.post<DocumentReviewAndActionsApiDetail>(
    ApiRoutes.closeDocumentReview({ id }),
    null,
    {
      params: {
        approved,
      },
    },
  );

  return res.data;
};

type DocumentReviewAndActionsApiDetail = DocumentReviewApiDetail & {
  actions: DocumentReviewActionDetail[];
};

const reopenReview = async ({ id }: CloseReviewParams) => {
  const res = await api.post<DocumentReviewAndActionsApiDetail>(
    ApiRoutes.reopenDocumentReview({ id }),
  );

  return res.data;
};

const reviewReactionApproveWithToken = async (params: {
  token: string;
  comment?: string;
}) => {
  const res = await api.post<DocumentReviewApiDetail>(
    ApiRoutes.approveDocumentReviewWithToken(),
    params,
  );

  return res.data;
};

const reviewReactionApprove = async ({
  reviewId,
  ...params
}: {
  reviewId: string;
  comment?: string;
}) => {
  const res = await api.post<DocumentReviewApiDetail>(
    ApiRoutes.approveDocumentReview({ reviewId }),
    params,
  );

  return res.data;
};

const reviewReactionRequestChanges = async ({
  reviewId,
  ...params
}: {
  reviewId: string;
  comment: string;
}) => {
  const res = await api.post<DocumentReviewApiDetail>(
    ApiRoutes.requestChangesDocumentReview({ reviewId }),
    params,
  );

  return res.data;
};

const clearReviewReaction = async (reviewId: string) => {
  const res = await api.post<DocumentReviewApiDetail>(
    ApiRoutes.clearReaction({ reviewId }),
  );

  return res.data;
};

const declineReviewReaction = async (reviewId: string) => {
  const res = await api.post<DocumentReviewApiDetail>(
    ApiRoutes.declineReaction({ reviewId }),
  );

  return res.data;
};

const createReviewComment = async ({
  reviewId,
  content,
}: CreateCommentParams) => {
  const res = await api.post<DocumentReviewApiDetail>(
    ApiRoutes.documentReviewComments({ reviewId }),
    {
      content,
    },
  );

  return res.data;
};

const createReviewTask = async ({
  reviewId,
  commentId,
  title,
}: CreateTaskParams) => {
  const res = await api.post<DocumentReviewApiDetail>(
    ApiRoutes.documentReviewTasks({ reviewId }),
    {
      title,
      commentId,
    },
  );

  return res.data;
};

const patchReviewTask = async ({
  reviewId,
  taskId,
  title,
  status,
}: UpdateTaskParams) => {
  const res = await api.patch<DocumentReviewApiDetail>(
    ApiRoutes.documentReviewTask({ reviewId, taskId }),
    {
      title,
      status,
    },
  );

  return res.data;
};

const destroyReviewTask = async ({ reviewId, taskId }: DeleteTaskParams) => {
  const res = await api.delete<DocumentReviewApiDetail>(
    ApiRoutes.documentReviewTask({ reviewId, taskId }),
  );
  return res.data;
};

//
// Hooks
//

export const useCreateDocumentReview = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createDocumentReview,
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: docReviewKeys().all }),
  });
};

export const useUpdateReview = (id: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: { title?: string; description?: string }) =>
      updateReview({ id, data }),
    onSuccess: (data) => updateDocReviewCache(id, data, queryClient),
  });
};

export const useResendInvitation = (
  reviewId: string,
  reviewerId: AgentableId,
) => {
  return useMutation({
    mutationFn: () => resendInvitation({ id: reviewId, reviewerId }),
    onSuccess: () =>
      toast("We'll send a new invitation to this review", { type: "success" }),
  });
};

export const useSignReview = (reviewId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: Omit<SignDocumentParams, "reviewId">) =>
      signReview({ reviewId, ...data }),
    onSuccess: (data) => updateDocReviewCache(reviewId, data, queryClient),
  });
};

export const useUpdateReviewers = (id: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: { reviewerIds: string[] }) =>
      updateReviewers({ id, data }),
    onSuccess: (data) => updateDocReviewCache(id, data, queryClient),
  });
};

export const useCloseReview = (id: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (approved?: boolean) => closeReview({ id, approved }),
    onSuccess: (data) => {
      updateDocReviewCache(
        id,
        { documentReview: data.documentReview },
        queryClient,
      );
      queryClient.setQueryData(docReviewKeys().actions(id), {
        actions: data.actions,
      });
    },
  });
};

export const useReopenReview = (id: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => reopenReview({ id }),
    onSuccess: (data) => {
      updateDocReviewCache(
        id,
        { documentReview: data.documentReview },
        queryClient,
      );
      queryClient.setQueryData(docReviewKeys().actions(id), {
        actions: data.actions,
      });
    },
  });
};

type ReactionWithTokenParams = {
  token: string;
  comment?: string;
};
export const useApproveWithToken = () => {
  const approveWithToken = useMutation({
    mutationFn: (params: ReactionWithTokenParams) =>
      reviewReactionApproveWithToken(params),
  });

  return approveWithToken;
};

type ReactionParams = {
  comment: string;
};
export const useReactions = (reviewId: string) => {
  const queryClient = useQueryClient();

  const approve = useMutation({
    mutationFn: (params?: ReactionParams) =>
      reviewReactionApprove({ reviewId, comment: params?.comment }),
    onSuccess: (data) => {
      updateDocReviewCache(reviewId, data, queryClient);
      queryClient.invalidateQueries({
        queryKey: docReviewKeys().actions(reviewId),
      });
    },
  });

  const requestChanges = useMutation({
    mutationFn: (params: ReactionParams) =>
      reviewReactionRequestChanges({ reviewId, comment: params.comment }),
    onSuccess: (data) => {
      updateDocReviewCache(reviewId, data, queryClient);
      queryClient.invalidateQueries({
        queryKey: docReviewKeys().actions(reviewId),
      });
    },
  });

  const clearReaction = useMutation({
    mutationFn: () => clearReviewReaction(reviewId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: docReviewKeys().detail(reviewId),
      });
      queryClient.invalidateQueries({
        queryKey: docReviewKeys().actions(reviewId),
      });
    },
  });

  const decline = useMutation({
    mutationFn: () => declineReviewReaction(reviewId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: docReviewKeys().detail(reviewId),
      });
      queryClient.invalidateQueries({
        queryKey: docReviewKeys().actions(reviewId),
      });
    },
  });

  return { approve, clearReaction, requestChanges, decline };
};

export const useCreateDocumentReviewComment = (reviewId: string) => {
  const queryClient = useQueryClient();
  const { user } = useAuth();

  return useMutation({
    mutationFn: ({ content }: Omit<CreateCommentParams, "reviewId">) =>
      createReviewComment({ reviewId, content }),
    onMutate: (args) =>
      beginDocReviewMutation(queryClient, reviewId, (mutationId) => [
        {
          type: "add_comment",
          mutationId,
          content: args.content,
          account: user!!,
        },
      ]),
    onSuccess: (data, _args, context) =>
      resolveDocReviewMutation(queryClient, reviewId, data, context),
    onError: (_err, _args, context) =>
      resolveDocReviewMutation(queryClient, reviewId, undefined, context),
  });
};

export const useCreateReviewTask = (reviewId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ title, commentId }: Omit<CreateTaskParams, "reviewId">) =>
      createReviewTask({ reviewId, title, commentId }),
    onMutate: (args) =>
      beginDocReviewMutation(queryClient, reviewId, (mutationId) => [
        {
          type: "create_task",
          mutationId,
          title: args.title,
        },
      ]),
    onSuccess: (data, _args, context) => {
      resolveDocReviewMutation(queryClient, reviewId, data, context);
      queryClient.invalidateQueries({
        queryKey: docReviewKeys().actions(reviewId),
      });
    },
    onError: (_err, _args, context) =>
      resolveDocReviewMutation(queryClient, reviewId, undefined, context),
  });
};

export const usePatchReviewTask = (reviewId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      taskId,
      title,
      status,
    }: Omit<UpdateTaskParams, "reviewId">) =>
      patchReviewTask({ reviewId, taskId, title, status }),
    onMutate: (args) =>
      beginDocReviewMutation(queryClient, reviewId, (mutationId) => [
        {
          type: "update_task",
          mutationId,
          taskId: args.taskId,
          title: args.title,
          status: args.status,
        },
      ]),
    onSuccess: (data, _args, context) => {
      resolveDocReviewMutation(queryClient, reviewId, data, context);
      queryClient.invalidateQueries({
        queryKey: docReviewKeys().actions(reviewId),
      });
    },
    onError: (_err, _args, context) =>
      resolveDocReviewMutation(queryClient, reviewId, undefined, context),
  });
};

export const useDestroyReviewTask = (reviewId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ taskId }: Omit<DeleteTaskParams, "reviewId">) =>
      destroyReviewTask({ reviewId, taskId }),
    onMutate: (args) =>
      beginDocReviewMutation(queryClient, reviewId, (mutationId) => [
        {
          type: "destroy_task",
          mutationId,
          taskId: args.taskId,
        },
      ]),
    onSuccess: (data, _args, context) => {
      resolveDocReviewMutation(queryClient, reviewId, data, context);
      queryClient.invalidateQueries({
        queryKey: docReviewKeys().actions(reviewId),
      });
    },
    onError: (_err, _args, context) =>
      resolveDocReviewMutation(queryClient, reviewId, undefined, context),
  });
};

export const useDocumentReview = (id: string) => {
  const query = useQuery({
    queryFn: ({ signal }) => getDocumentReview({ id }, { signal }),
    enabled: Boolean(id),
    queryKey: docReviewKeys().detail(id),
  });

  const create = useCreateDocumentReview();
  const updateReviewers = useUpdateReviewers(id);
  const close = useCloseReview(id);
  const reactions = useReactions(id);
  const createComment = useCreateDocumentReviewComment(id);

  const data = useMemo(() => {
    return {
      documentReview: applyLocalOperations(query.data?.documentReview),
      meta: query?.data?.meta,
    };
  }, [query.data]);

  return {
    data,
    remote: query.data,
    query,
    create,
    updateReviewers,
    close,
    ...reactions,
    createComment,
  };
};

export const useDocumentReviewActions = (id: string, enabled: boolean) => {
  const query = useQuery({
    queryFn: ({ signal }) => getDocumentReviewActions({ id }, { signal }),
    queryKey: docReviewKeys().actions(id),
    enabled,
  });

  return {
    data: query.data,
    query,
  };
};

export const useDocumentReviews = (
  params: GetDocumentReviewsParams = {},
): Omit<
  CollectionHook<
    DocumentReview,
    DocumentReviewsApiIndex,
    DocumentReviewApiDetail,
    CreateDocumentReviewParams
  >,
  "update" | "destroy"
> => {
  const query = useQuery({
    queryKey: docReviewKeys().list(params),
    placeholderData: keepPreviousData,
    queryFn: ({ signal }) => getDocumentReviews(params, { signal }),
  });

  const create = useCreateDocumentReview();

  return { data: query.data, query, create };
};

const DOCUMENT_REVIEW_BASE_KEY = "documentReviews";
export const DOC_REVIEW_KEYS: CacheKeyLibrary = {
  baseKey: DOCUMENT_REVIEW_BASE_KEY,
  detailKey: "documentReview",
  collectionKey: "documentReviews",
};

export const docReviewKeys = () => {
  const keys = entityKeys<GetDocumentReviewsParams>(DOCUMENT_REVIEW_BASE_KEY);
  return {
    ...keys,
    actions: (id: string) => [...keys.detail(id), "actions"],
  };
};

export const updateDocReviewCache = (
  id: string,
  newData: DocumentReviewApiDetail,
  queryClient: QueryClient,
) =>
  updateCache<
    PendingDocumentReviewOperation,
    DocumentReviewApiDetail,
    DocumentReviewsApiIndex,
    DocumentReview,
    DocumentReview
  >(
    id,
    newData.documentReview,
    (d) => d.publicUid,
    getDocReviewPending,
    setDocReviewPending,
    queryClient,
    DOC_REVIEW_KEYS,
  );

export const clearDocReviewCache = (id: string, queryClient: QueryClient) =>
  clearCache<DocumentReviewApiDetail>(
    id,
    (d) => d.documentReview.publicUid,
    queryClient,
    DOCUMENT_REVIEW_BASE_KEY,
  );

export const useDocumentReviewSubscription = (reviewId: string) => {
  const queryClient = useQueryClient();

  useEntitySubscription("document_review", reviewId, () => {
    queryClient.invalidateQueries({
      queryKey: docReviewKeys().detail(reviewId!),
    });
    queryClient.invalidateQueries({
      queryKey: docReviewKeys().actions(reviewId!),
    });
  });
};
